import { FETCH_DETAIL_PROJECT } from "../types";

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_DETAIL_PROJECT:
            
            return {
                ...state,
                ...action.payload
            }
    
        default:
            return state;
    }
}