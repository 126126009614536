import { combineReducers } from "redux";
import page from "./page";
import projectPage from "./projectPage";
import detailProject from "./detailProject";

export default combineReducers({
  page,
  projectPage,
  detailProject
});
